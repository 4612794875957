import { chakra, ChakraProps, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";
import subMinutes from "date-fns/subMinutes";
import parseISO from "date-fns/parseISO";
import { useTranslatedString } from "../../i18n/hooks";
import { NoSSR } from "../NoSSR";

type NewArticleMarkerProps = ChakraProps & {
	firstPublishedAt: string;
};

export const NewArticleMarker: ReactFC<NewArticleMarkerProps> = ({
	firstPublishedAt,
	...rest
}) => {
	const styles = useMultiStyleConfig("NewArticleMarker", {});
	const t = useTranslatedString();

	if (
		!firstPublishedAt ||
		parseISO(firstPublishedAt) < subMinutes(new Date(), 60)
	) {
		return null;
	}

	return (
		<NoSSR>
			<chakra.div __css={styles.container} {...rest}>
				<chakra.svg __css={styles.circleSvg} viewBox="0 0 16 16">
					<circle fill="currentColor" cx="8" cy="8" r="8" />
				</chakra.svg>
				<chakra.span __css={styles.span}>{t("newArticle")}</chakra.span>
			</chakra.div>
		</NoSSR>
	);
};
