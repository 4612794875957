import {
	Divider,
	GridItem,
	useMultiStyleConfig,
	VStack,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { BrandSlug } from "../portalstack.config";
import { useBrandLogo } from "../brandComponents";
import { Grid } from "./Grid/Grid";
import { Hx } from "./headings";
import { RankedTeaserList } from "./teasers/RankedTeaserList";
import { SingleOutlinedTeaser } from "./teasers/SingleOutlinedTeaser";
import { TextOnlyTeaser } from "./teasers/TextOnlyTeaser";
import { articleDataToTeaserData, TeaserData } from "./teasers/utils";
import { useTranslatedString } from "../i18n/hooks";
import type { ArticleTrackingType } from "../lib/gtm/dataLayer";
import { useBrandTheme } from "../theme/useBrandTheme";
import { sliceArray, truthy } from "../utils/misc";
import type { BreakpointKeys, CrossPromoData } from "../utils/types";
import { SiteLocale } from "../__generated__/types/graphql-codegen";

const desktopBreakpoint: BreakpointKeys = "lg";

type CrossPromoContextType =
	| undefined
	| {
			locale: SiteLocale;
			brandSlug: BrandSlug;
	  };

export const CrossPromoContext =
	React.createContext<CrossPromoContextType>(undefined);

const trackingType: ArticleTrackingType = "subbrand_clickout";

export const CrossPromoSection: ReactFC<{
	crossPromoData: CrossPromoData;
}> = ({ crossPromoData }) => {
	const t = useTranslatedString();

	const { colors } = useBrandTheme(crossPromoData.brandSlug);

	const styles = useMultiStyleConfig("CrossPromoSection", {
		desktopBreakpoint,
		backgroundColor: colors.brand.crossPromoBackground ?? "base.lightGray",
		primaryColor: colors.brand.primary ?? "base.darkGray",
	});

	const Logo = useBrandLogo(crossPromoData.brandSlug);

	const articles = useMemo(
		() =>
			crossPromoData.articles
				.map(articleDataToTeaserData)
				.filter(truthy)
				.map((t: TeaserData) => ({
					...t,
					trackingType,
				})),
		[crossPromoData.articles],
	);

	const [outlineds, textOnly] = useMemo(
		() => sliceArray(articles, 1),
		[articles],
	);

	const popularArticles = useMemo(
		() =>
			crossPromoData.popularArticles
				.map(articleDataToTeaserData)
				.filter(truthy),
		[crossPromoData.popularArticles],
	);

	return (
		<CrossPromoContext.Provider
			value={{
				locale: crossPromoData.locale,
				brandSlug: crossPromoData.brandSlug,
			}}
		>
			<Grid
				sx={styles.container}
				as="section"
				rowGap={8}
				templateColumns="repeat(12, 1fr)"
				templateRows="repeat(3, min-content)"
			>
				<GridItem
					rowStart={1}
					colSpan={{
						base: 12,
						lg: 4,
						xl: 3,
					}}
					sx={styles.logoWrapper}
				>
					<Logo sx={styles.logo} />
				</GridItem>

				{outlineds?.[0] && (
					<GridItem
						rowStart={{ lg: 2 }}
						colSpan={{
							base: 12,
							lg: 4,
							xl: 3,
						}}
					>
						<SingleOutlinedTeaser teaser={outlineds[0]} />
					</GridItem>
				)}
				<GridItem
					rowStart={{ lg: 2 }}
					colSpan={{
						base: 12,
						lg: 8,
						xl: 6,
					}}
				>
					<VStack spacing={8} divider={<Divider />}>
						{textOnly?.map((teaser) => (
							<TextOnlyTeaser
								teaser={teaser}
								key={teaser.urlParams.slug}
							/>
						))}
					</VStack>
				</GridItem>
				{popularArticles.length > 0 && (
					<GridItem
						rowStart={{ lg: 3, xl: 2 }}
						colStart={{ lg: 5 }}
						colSpan={{
							base: 12,
							lg: 8,
							xl: 3,
						}}
					>
						<Hx size="h3" sx={styles.popularArticlesHeading}>
							{t("popularArticles")}
						</Hx>
						<RankedTeaserList teasers={popularArticles} />
					</GridItem>
				)}
			</Grid>
		</CrossPromoContext.Provider>
	);
};
