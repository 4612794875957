import {
	chakra,
	ChakraProps,
	HeadingProps,
	Text,
	useMultiStyleConfig,
	createStylesContext,
	TextProps,
	Button,
} from "@chakra-ui/react";
import { useContext } from "react";
import formatDate from "date-fns/format";
import { parseISO } from "date-fns";
import { useRewriteContext } from "../../../context/RewriteContext";
import { ChakraHx } from "../../headings";
import type { CanonicalArticleUrlParams } from "../../../utils/getStaticPropsUtils";
import type { LinkProps } from "../../Link/Link";
import { ArticleLink } from "../../Link/ArticleLink";
import { NewArticleMarker } from "../../NewArticleMarker/NewArticleMarker";
import { TeaserVariant, TeaserVariantContext } from "../utils";
import { SiteLocale } from "../../../__generated__/types/graphql-codegen";
import type { ArticleTrackingType } from "../../../lib/gtm/dataLayer";
import type { BreakpointKeys } from "../../../utils/types";
import { CrossPromoContext } from "../../CrossPromoSection";
import { useTranslatedString } from "../../../i18n/hooks";

const desktopBreakpoint: BreakpointKeys = "mdlg";

export const [StylesProvider, useTeaserStyles] = createStylesContext("Teaser");

type TeaserProps = {
	trackingType?: ArticleTrackingType;
	urlParams: CanonicalArticleUrlParams;
	variant?: TeaserVariant;
	highlighted?: boolean;
	company: {
		wkn?: string;
	};
};

export const Teaser: ReactFC<
	Omit<LinkProps, "href" | "as" | "locale"> & TeaserProps
> = ({
	children,
	urlParams,
	highlighted = false,
	variant = "regular" as TeaserVariant,
	company,
	trackingType,
	...rest
}) => {
	const styles = useMultiStyleConfig("Teaser", {
		desktopBreakpoint,
		variant,
	});
	const crossPromoContext = useContext(CrossPromoContext);
	const { locale: currentLocale } = useRewriteContext();
	const locale: SiteLocale = crossPromoContext?.locale ?? currentLocale;
	const brand = crossPromoContext?.brandSlug;

	return (
		<TeaserVariantContext.Provider value={variant}>
			<StylesProvider value={styles}>
				<chakra.article
					data-teaser-type={variant}
					__css={styles.container}
					className={
						highlighted ? "mmg-teaser-highlighted" : undefined
					}
				>
					<ArticleLink
						sx={styles.containerLink}
						variant="no-underline"
						urlParams={urlParams}
						brand={brand}
						locale={locale}
						trackingType={trackingType}
						isSponsoredPost={highlighted}
						{...rest}
					>
						<chakra.div __css={styles.inner}>{children}</chakra.div>
					</ArticleLink>
				</chakra.article>
			</StylesProvider>
		</TeaserVariantContext.Provider>
	);
};

export const TeaserCategory: ReactFC = ({ children }) => {
	const styles = useTeaserStyles();

	return <chakra.div sx={styles.category}>{children}</chakra.div>;
};

export const TeaserDate: ReactFC<{ date: string }> = ({ date }) => {
	const styles = useTeaserStyles();

	const formattedDate = formatDate(parseISO(date), "dd.MM.yyyy");

	return (
		<chakra.time dateTime={date} sx={styles.date}>
			{formattedDate}
		</chakra.time>
	);
};

export const TeaserTitle: ReactFC<HeadingProps> = ({ children, ...rest }) => {
	const styles = useTeaserStyles();

	return (
		<ChakraHx sx={styles.title} {...rest}>
			{children}
		</ChakraHx>
	);
};

export const TeaserBody: ReactFC<ChakraProps> = ({ children }) => {
	const styles = useTeaserStyles();

	return <chakra.div sx={styles.body}>{children}</chakra.div>;
};

export const TeaserLinkButton: ReactFC<ChakraProps> = ({
	children,
	...rest
}) => {
	const styles = useTeaserStyles();
	const t = useTranslatedString();

	return (
		<chakra.div __css={styles.buttonLink}>
			<Button as="span" variant="black" width="full">
				{t("toArticle")}
			</Button>
		</chakra.div>
	);
};

export const TeaserExcerpt: ReactFC<
	ChakraProps & {
		firstPublishedAt: string;
		noOfLines?: TextProps["noOfLines"];
	}
> = ({ children, firstPublishedAt, noOfLines }) => {
	const styles = useTeaserStyles();

	return (
		<>
			<Text sx={styles.teaserText} noOfLines={noOfLines}>
				{children}
			</Text>
			<NewArticleMarker
				sx={styles.newArticleMarker}
				firstPublishedAt={firstPublishedAt}
			/>
		</>
	);
};
