import { useTranslatedString } from "../../i18n/hooks";
import { TrackingContextProvider } from "../../lib/gtm/TrackingContext";
import {
	Teaser,
	TeaserBody,
	TeaserCategory,
	TeaserDate,
	TeaserExcerpt,
	TeaserTitle,
} from "./parts/Teaser";
import { TeaserData } from "./utils";

export const TextOnlyTeaser = ({ teaser }: { teaser: TeaserData }) => {
	const t = useTranslatedString();
	const {
		urlParams,
		title,
		category,
		teaserText,
		highlighted,
		company,
		trackingType,
		publicationDate,
	} = teaser;

	return (
		<TrackingContextProvider
			value={{
				article: {
					_firstPublishedAt: urlParams._firstPublishedAt,
					company,
					title,
				},
			}}
		>
			<Teaser
				variant="text-only"
				key={urlParams.slug}
				urlParams={urlParams}
				highlighted={highlighted}
				aria-label={`${t("article", { count: 1 })}: ${title}`}
				company={company}
				trackingType={trackingType}
			>
				<TeaserBody>
					<TeaserCategory>
						{/* We want to keep the tag for spacing if no category is set */}
						{category ?? <>&nbsp;</>}
					</TeaserCategory>
					<TeaserTitle noOfLines={{ base: 2, lg: 999 }}>
						{title}
					</TeaserTitle>
					{publicationDate && <TeaserDate date={publicationDate} />}
					<TeaserExcerpt
						noOfLines={2}
						firstPublishedAt={urlParams._firstPublishedAt}
					>
						{teaserText}
					</TeaserExcerpt>
				</TeaserBody>
			</Teaser>
		</TrackingContextProvider>
	);
};
