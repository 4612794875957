import { useTranslatedString } from "../../i18n/hooks";
import { TrackingContextProvider } from "../../lib/gtm/TrackingContext";
import { truthy } from "../../utils/misc";
import { Teaser, TeaserTitle } from "./parts/Teaser";
import { TeaserList, TeaserListItem } from "./parts/TeaserList";
import { TeaserData } from "./utils";

export const RankedTeaserList: ReactFC<{
	teasers: Array<TeaserData>;
	max?: number;
}> = ({ teasers, max = 5 }) => {
	const t = useTranslatedString();

	return (
		<TeaserList>
			{teasers
				.filter(truthy)
				.slice(0, max)
				.map(
					({
						title,
						highlighted,
						urlParams,
						company,
						trackingType,
					}) => (
						<TrackingContextProvider
							key={urlParams.slug}
							value={{
								article: {
									_firstPublishedAt:
										urlParams._firstPublishedAt,
									company,
									title,
								},
							}}
						>
							<TeaserListItem highlighted={highlighted}>
								<Teaser
									variant="list"
									highlighted={false}
									urlParams={urlParams}
									company={company}
									aria-label={`${t("article", {
										count: 1,
									})}: ${title}`}
									trackingType={trackingType}
								>
									<TeaserTitle>{title}</TeaserTitle>
								</Teaser>
							</TeaserListItem>
						</TrackingContextProvider>
					),
				)}
		</TeaserList>
	);
};
