import {
	chakra,
	useMultiStyleConfig,
	createStylesContext,
} from "@chakra-ui/react";
import React from "react";

const [StylesProvider, useTeaserListStyles] = createStylesContext("TeaserList");

export const TeaserList: ReactFC = ({ children }) => {
	const styles = useMultiStyleConfig("Teaser", {
		variant: "list",
	});

	return (
		<StylesProvider value={styles}>
			<chakra.ol __css={styles.list}>{children}</chakra.ol>
		</StylesProvider>
	);
};

export const TeaserListItem: ReactFC<{ highlighted?: boolean }> = ({
	highlighted,
	children,
}) => {
	const styles = useTeaserListStyles();

	return (
		<chakra.li
			className={highlighted ? "mmg-teaser-highlighted" : undefined}
			sx={styles.listitem}
		>
			{children}
		</chakra.li>
	);
};
