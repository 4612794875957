import { useTranslatedString } from "../../i18n/hooks";
import { TrackingContextProvider } from "../../lib/gtm/TrackingContext";
import {
	Teaser,
	TeaserBody,
	TeaserExcerpt,
	TeaserTitle,
	TeaserDate,
} from "./parts/Teaser";
import { TeaserImage } from "./parts/TeaserImage";
import { findImageData, TeaserData } from "./utils";

export const SingleOutlinedTeaser: ReactFC<{
	teaser: TeaserData;
}> = ({ teaser }) => {
	const t = useTranslatedString();
	const {
		title,
		teaserText,
		company,
		image,
		urlParams,
		trackingType,
		publicationDate,
	} = teaser;
	const thisImage = findImageData(image);

	return (
		<TrackingContextProvider
			value={{
				articleImage: {
					src: thisImage?.src ?? undefined,
					desc:
						thisImage?.alt ??
						thisImage?.src?.split("/").pop() ??
						undefined,
				},
				article: {
					_firstPublishedAt: urlParams._firstPublishedAt,
					company,
					title,
				},
			}}
		>
			<Teaser
				variant="outlined"
				urlParams={urlParams}
				highlighted={false} // we don't want to highlight this teaser type
				aria-label={`${t("article", { count: 1 })}: ${title}`}
				company={company}
				trackingType={trackingType}
			>
				<TeaserBody>
					{publicationDate && <TeaserDate date={publicationDate} />}
					<TeaserTitle noOfLines={{ base: 2, lg: 999 }}>
						{title}
					</TeaserTitle>
					<TeaserExcerpt
						noOfLines={4}
						firstPublishedAt={urlParams._firstPublishedAt}
					>
						{teaserText}
					</TeaserExcerpt>
				</TeaserBody>
				<TeaserImage
					alt={thisImage?.alt ?? thisImage?.title ?? title}
					wkn={company.wkn}
					imageData={image}
				/>
			</Teaser>
		</TrackingContextProvider>
	);
};
