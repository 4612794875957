import { AspectRatio, chakra } from "@chakra-ui/react";
import { useContext, useMemo } from "react";
import { articleTeaserImageSettings } from "../../../portalstack.config";
import { useBrandContext } from "../../../context/BrandContext";
import { normalizeResponsiveImageData } from "../../../lib/normalizeGalleryData";
import { CMSImage } from "../../CMSImage";
import { TeaserData, TeaserVariantContext } from "../utils";
import { useTeaserStyles } from "./Teaser";

const fullSize = {
	width: "100%",
	height: "100%",
};

export const TeaserImage: ReactFC<{
	imageData?: TeaserData["image"];
	wkn?: string | null;
	alt: string;
}> = ({ imageData, wkn, alt }) => {
	const styles = useTeaserStyles();
	const { brandImage } = useBrandContext();

	const variant = useContext(TeaserVariantContext);

	const articleTeaser = useMemo(() => {
		if (
			variant === "list" ||
			variant === "text-only" ||
			variant === "single-eyecatcher" ||
			variant === "overview"
		) {
			return false;
		}

		const specificVariant = imageData?.[variant];
		const regularAsFallback = imageData?.regular;

		return specificVariant ?? regularAsFallback;
	}, [imageData, variant]);

	if (articleTeaser === false) {
		return <div />;
	}

	const image =
		articleTeaser ??
		normalizeResponsiveImageData(brandImage?.image?.responsiveImage);

	if (!image) {
		return null;
	}

	return (
		<chakra.div __css={styles.imageWrapper}>
			<AspectRatio
				ratio={
					articleTeaserImageSettings[variant].responsiveAspectRatio ??
					articleTeaserImageSettings[variant].fileAspectRatio
				}
			>
				<CMSImage
					sx={styles.image}
					responsiveImage={image}
					alt={alt}
					divStyle={fullSize}
					pictureStyle={{
						...fullSize,
						objectFit: "cover",
					}}
					aria-label={wkn ? `WKN: ${wkn}` : image.title ?? undefined}
				/>
			</AspectRatio>
			{wkn && (
				<chakra.p __css={styles.wkn} className="mmg-teaser-wkn">
					{wkn}
				</chakra.p>
			)}
		</chakra.div>
	);
};
